<template>
    <pagination title="Details" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :hasEntities="true" :checkSize="false" :with-edit-icon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:items>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Name
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="name"
                        :action="editing? updateAction: null"
                        fieldId="name"
                        :show-label="false"
                        v-model="updateActionData['name']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Sage Reference
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="sage_reference"
                        :action="editing? updateAction: null"
                        fieldId="sage_reference"
                        :show-label="false"
                        v-model="updateActionData['sage_reference']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Credit Limit
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="credit_limit"
                        :action="editing? updateAction: null"
                        fieldId="credit_limit"
                        :show-label="false"
                        v-model="updateActionData['credit_limit']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Start Date
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="credit_limit_start_date"
                        :action="editing? updateAction: null"
                        fieldId="credit_limit_start_date"
                        :show-label="false"
                        v-model="updateActionData['credit_limit_start_date']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Payment Terms in Days
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="payment_terms_in_days"
                        :action="editing? updateAction: null"
                        fieldId="payment_terms_in_days"
                        :show-label="false"
                        v-model="updateActionData['payment_terms_in_days']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Payment Terms From
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="payment_terms_from"
                        :action="editing? updateAction: null"
                        fieldId="payment_terms_from"
                        :show-label="false"
                        v-model="updateActionData['payment_terms_from']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Notes
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="notes"
                        :action="editing? updateAction: null"
                        fieldId="notes"
                        :show-label="false"
                        v-model="updateActionData['notes']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Tax Code
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="tax_code"
                        :action="editing? updateAction: null"
                        fieldId="tax_code"
                        :show-label="false"
                        v-model="updateActionData['tax_code']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Short Description
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="short_description"
                        :action="editing? updateAction: null"
                        fieldId="short_description"
                        :show-label="false"
                        v-model="updateActionData['short_description']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Nominal Code
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="nominal_code"
                        :action="editing? updateAction: null"
                        fieldId="nominal_code"
                        :show-label="false"
                        v-model="updateActionData['nominal_code']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
  name: 'SupplierDetailsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
          default: false
      },
      updateAction: {
        type: Object,
          default: null
      },
      updateActionData:{
        type: Object,
          default: null
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
